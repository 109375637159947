import { useState, createRef, useEffect } from 'react'
import jsQR from "jsqr";


const videoElementRef = createRef<HTMLVideoElement>()
const canvasRef = createRef<HTMLCanvasElement>()

function drawLine(begin:{x: number, y: number}, end:{x: number, y: number}, color:string) {
  const canvas = canvasRef.current!.getContext("2d");

  if(canvas) {
    canvas.beginPath();
    canvas.moveTo(begin.x, begin.y);
    canvas.lineTo(end.x, end.y);
    canvas.lineWidth = 4;
    canvas.strokeStyle = color;
    canvas.stroke();
  }
}

const writeText = (x:number, y:number, text:string)=> {
  const context = canvasRef.current!.getContext("2d");
  context!.fillStyle = "white";
  context!.font = "bold 18px Arial";
  context!.fillText(text, x, y);
}

function QrReader() {
  const [statusText, setStatusText] = useState('')
  const [videoStarted, setVideoStarted] = useState(false)
  let foundCodeInfo:any = null 


  const tick = ()=> {
    const videoElement = videoElementRef.current
    const canvasElement = canvasRef.current


    if (videoElement && canvasElement) {
      if (videoElement.readyState === videoElement.HAVE_ENOUGH_DATA) { 
        const canvas_ctx = canvasElement.getContext("2d");
        canvasElement.height = 400 // videoElement.videoHeight;
        canvasElement.width = 400 //videoElement.videoWidth;
        let video_centre_x = videoElement.videoWidth / 2
        let video_centre_y = videoElement.videoHeight / 2

        let size = canvasElement.width
        if(canvasElement.height <  canvasElement.width) {
          size = canvasElement.height
        }
//        canvas_ctx!.save()
//        canvas_ctx!.scale(-1,1)
//        canvas_ctx!.drawImage(videoElement, (canvasElement.width*-1), 0, canvasElement.width, canvasElement.height);
        canvas_ctx!.drawImage(videoElement, video_centre_x-200,video_centre_y-200, 400,400, 0,0, 400, 400);

        canvas_ctx!.restore()
        const imageData = canvas_ctx!.getImageData(0, 0, canvasElement.width, canvasElement.height);

        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });

       

        if (code) {
          foundCodeInfo = {} 
          foundCodeInfo["location"] = {}
          foundCodeInfo.location["topLeftCorner"] =  code.location.topLeftCorner
          foundCodeInfo.location["topRightCorner"] =  code.location.topRightCorner
          foundCodeInfo.location["bottomRightCorner"] =  code.location.bottomRightCorner
          foundCodeInfo.location["bottomLeftCorner"] =  code.location.bottomLeftCorner
          foundCodeInfo.statusText = `V ${code.version}`
          foundCodeInfo.url = `${code.data} `


         // setStatusText(`Found QR code ${JSON.stringify(code)}`)
          setStatusText(`V ${code.version} QR Code:  ${code.data} `)
          const lineColour = "#00ff00"

          drawLine(code.location.topLeftCorner, code.location.topRightCorner, lineColour);
          drawLine(code.location.topRightCorner, code.location.bottomRightCorner, lineColour);
          drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, lineColour);
          drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, lineColour);
         
          drawLine(code.location.topRightFinderPattern, code.location.topLeftFinderPattern, lineColour);
         
        }  else {

          if(foundCodeInfo) {
            const lastCodeFoundInfo = foundCodeInfo

            const lineColour = "#888888"

            drawLine(lastCodeFoundInfo.location.topLeftCorner, lastCodeFoundInfo.location.topRightCorner,lineColour);
            drawLine(lastCodeFoundInfo.location.topLeftCorner, lastCodeFoundInfo.location.topRightCorner, lineColour);
            drawLine(lastCodeFoundInfo.location.topRightCorner, lastCodeFoundInfo.location.bottomRightCorner, lineColour);
            drawLine(lastCodeFoundInfo.location.bottomRightCorner, lastCodeFoundInfo.location.bottomLeftCorner, lineColour);
            drawLine(lastCodeFoundInfo.location.bottomLeftCorner, lastCodeFoundInfo.location.topLeftCorner, lineColour);
      

            }
        }
        if(foundCodeInfo) {
          const x = foundCodeInfo.location.topLeftCorner.x
          const y = foundCodeInfo.location.topLeftCorner.y + 30

          writeText(x,y, foundCodeInfo.statusText) 

          writeText(x,y+30, foundCodeInfo.url) 
        }
      }
    }
    requestAnimationFrame(tick)
  }



  function startCamera() {
    setVideoStarted(true)
    console.log("Button pressed")
    if (videoElementRef.current) {
      const videoElement = videoElementRef.current
      navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function (stream) {
        videoElement.srcObject = stream;
        videoElement.setAttribute("playsinline", "true"); // required to tell iOS safari we don't want fullscreen
        videoElement.play();
        requestAnimationFrame(tick);
      });
    }
  }

  return <div>
    <div className="inner">
    <h1>Read a QR code</h1>

      <div style={{display:"none"}}>
      <video
        ref={videoElementRef}
        autoPlay={true}
        muted={true}
        playsInline={true}
        style={{ outline: '1px solid' }}
      ></video></div>

      {/*
      <div style={{ outline: '1px solid', position: 'absolute', background: '#ffffff', opacity: 0.8}} >
      <h2  > ChecQR Fridge checker </h2>
      <ul>
        <li>✅ Fridge 1</li>
        <li>⏱️ Fridge 2</li>
        <li>⏱️ Fridge 3</li>

      </ul>

</div>*/}
      <canvas ref={canvasRef} style={{ outline: '1px solid' }}
      ></canvas>

      <div>
        {statusText}
      </div>



      {(videoStarted)?"":<button onClick={() => { startCamera() }}>Start camera</button>}

    </div></div>
}

export default QrReader