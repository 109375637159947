import React, { useState, useEffect, createRef } from 'react'

import qrcode from 'qrcode-generator'

function QrCreator() {
    const [qrData, setQrData] = useState('HTTP://PINGPENGUIN.COM')
    const [qrImageData, setQrImageData] = useState<string | null>(null)
    const [redundancyLevel, setRedundancyLevel] = useState('H')
    const qrCodeTagRef = createRef<HTMLDivElement>()
    const moduleCountRef = createRef<HTMLDivElement>()

    function getModeForDataString(dataSting: string) {
        console.log(dataSting)
        if(/^\d+$/.test(dataSting)) {
          console.log("NUMERIC")
          return 'Numeric'
        }
        //  /^[A-Z0-9]+$/
        if(/^[A-Z0-9+$%*+.\/:-]+$/.test(dataSting)) {
          return 'Alphanumeric'
        }
        return 'Byte'
      }

    useEffect(() => {
      console.log(`Level ${redundancyLevel}, data: ${qrData}`)
      const qr = qrcode(0, redundancyLevel as any);
      const mode = getModeForDataString(qrData)
      const padding = 20
      const radius = 4
      const cellsize = 10

      qr.addData(qrData, mode);
      qr.make();
      
      const modulecount = qr.getModuleCount()
      console.log(`qr code has module coutnt of ${modulecount}`)
      let svg = `<svg height="${modulecount*cellsize + padding + padding +2 }" width="${modulecount*cellsize +padding  + padding +2 }">`
      svg+=`<rect width="${modulecount*cellsize + 40}" height="${modulecount*cellsize + padding + padding}" stroke="red" stroke-width="1" fill="none" />`
      for(let y=0; y<modulecount; y++) {
        for(let x=0; x<modulecount; x++) {
            if(qr.isDark(x,y)) {

              svg+=`<circle cx="${x*cellsize  + radius+ padding +1 }" cy="${y*cellsize + radius + padding+1}" r="${radius}" stroke="red" stroke-width="1" fill="none" />`
        //      svg+=`<circle cx="${x*10 + 25}" cy="${y*10 + 20}" r="2" stroke="red" stroke-width="1" fill="none" />`
        //      svg+=`<circle cx="${x*10 + 20 } " cy="${y*10 + 25}" r="2" stroke="red" stroke-width="1" fill="none" />`
        //      svg+=`<circle cx="${x*10 + 25}" cy="${y*10 + 25}" r="2" stroke="red" stroke-width="1" fill="none" />`

            }
          }
     }
     svg+="</svg>"
     console.log(svg)
      const version = ((qr.getModuleCount() - 21) / 4) + 1
      const imageData = qr.createSvgTag(4)
      qrCodeTagRef.current!.innerHTML = imageData
      setQrImageData(imageData)
      moduleCountRef.current!.innerHTML = `Version ${version} ${mode}`
    }, [qrData, redundancyLevel, qrCodeTagRef, moduleCountRef])
  
  
    const download = () => {
      if (qrImageData) {
        const timestamp = Date.now()
        var element = document.createElement('a')
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(qrImageData))
        element.setAttribute('download', `qrcode-${timestamp}.svg`)
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
  
  
  
    return <div>
      <div className="inner">
        <h1>Generate a QR code</h1>
  
        <div className={`columns`}>
          <div className={`column left`}>
            <p>URL:</p>
            <input
              type='text'
              value={qrData}
              onChange={(e) => { setQrData(e.target.value) }}
            />
            <p>Redundancy: </p>
            <select value={redundancyLevel}
              onChange={(e) => { setRedundancyLevel(e.target.value) }}
            >
              <option value='L'>L - Low</option>
              <option value='M'>M - Medium </option>
              <option value='Q'>Q - Quartile </option>
              <option value='H'>H - High</option>
            </select>
          </div>
          <div className={`column right`}>
            <div ref={qrCodeTagRef}></div>
            <div ref={moduleCountRef}></div>
            {qrImageData && <button onClick={download}>Download</button>}
          </div>
        </div>
      </div>
  
    </div>
  }
  
  export default QrCreator