import React, { useState, useEffect, createRef } from 'react'
import QrReader from './components/QrReader';
import QrCreator from './components/QrCreator';



function App() {
  const [mode, setMode] = useState('READ')
  





  return <div className="container">

    <img src='circle-kakapo-outline.svg' className={`logo`} />

   
   {(mode=='CREATE')?<div>
    <button onClick ={ ()=> {setMode('READ')} }>switch to reader</button>
    <QrCreator/>
    </div>:<div>
    <button onClick ={ ()=> {setMode('CREATE')} } >switch to creator</button>
    <QrReader/>
    </div>}


  </div>
}

export default App
